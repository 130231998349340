import classNames from '../../../../lib/classnames';

export interface Heading4Props {
    className?: string;
    level?: number;
    children: string;
}

export default function Heading4({ className, level = 4, children }: Heading4Props): JSX.Element {
    return (
        <h4
            className={classNames(
                'font-sans text-base lg:text-lg font-bold text-gray-900 mt-[2em] mb-[1em] dark:text-white',
                className,
            )}
            aria-level={level}>
            {children}
        </h4>
    );
}
