import { useEffect, useState } from 'react';

import routes from '../../../../config/routes';
import type { IUser } from '../../../../features/user';
import classNames from '../../../../lib/classnames';
import { links } from '../../../../lib/website-static-links';
import Brand from '../../../basic/Brand';
import BrandIcon from '../../../basic/BrandIcon';
import Dropdown from '../../../basic/Dropdown';
import AuthenticatedNav from '../AuthenticatedNav';
import UnauthenticatedNav from '../UnauthenticatedNav';

export interface WebsiteHeaderProps {
    user?: IUser;
    className?: string;
}

export default function WebsiteHeader({ user, className }: WebsiteHeaderProps): JSX.Element {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    return (
        <div
            className={classNames(
                'fixed top-0 left-0 right-0 p-2 px-3 bg-white lg:px-11 lg:py-6 dark:bg-gray-800 dark:border-gray-700 z-20',
                className,
                scroll ? 'bg-white' : 'bg-transparent',
            )}>
            <div className="">
                <div className="flex flex-row items-center">
                    <div>
                        <Brand className="hidden sm:block mr-10 !my-0" />
                        <BrandIcon
                            className="sm:hidden block mr-10 !my-0"
                            url={routes.home.getUrl()}
                        />
                    </div>
                    <div className="flex flex-row flex-grow items-center space-x-6">
                        <Dropdown
                            className="text-left"
                            links={[
                                {
                                    id: 1,
                                    getUrl: () => `${links.jobs}&utm_content=header-jobs-link`,
                                    children: 'Jobs',
                                },
                                {
                                    id: 2,
                                    getUrl: () =>
                                        `${links.contact}&utm_content=header-contact-link`,
                                    children: 'Contact',
                                },
                            ]}>
                            À Propos
                        </Dropdown>
                        <Dropdown
                            className="text-left"
                            links={[
                                {
                                    id: 1,
                                    getUrl: () =>
                                        `${links.features}&utm_content=header-features-link`,
                                    children: 'Fonctionnalités',
                                },
                                {
                                    id: 2,
                                    getUrl: () => `${links.prices}&utm_content=header-prices-link`,
                                    children: 'Tarifs',
                                },
                            ]}>
                            Produit
                        </Dropdown>
                    </div>
                    <div className="flex items-center justify-end">
                        {user ? (
                            <AuthenticatedNav
                                username={user.username}
                                email={user.email}
                                provider_metadata={user?.avatar?.provider_metadata}
                            />
                        ) : (
                            <UnauthenticatedNav />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
