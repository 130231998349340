import IconMoon from 'bootstrap-icons/icons/moon.svg';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

import routes from '../../../../config/routes';
import { links, UTM_SOURCE } from '../../../../lib/website-static-links';
import Brand from '../../../basic/Brand';
import ContainerLarge from '../../../basic/Container/ContainerLarge';
import Button from '../../../elements/Button';
import Heading4 from '../../../elements/Heading/Heading4';
import HorizontalRule from '../../../elements/HorizontalRule';
import Link from '../../../elements/Link';
import switchTheme from '../../SwitchThemeButton/helper';

export interface WebsiteFooterProps {}

export default function WebsiteFooter({}: WebsiteFooterProps): JSX.Element {
    const { theme } = useTheme();

    const [themeButtonText, setThemeButtonText] = useState<'Mode clair' | 'Mode sombre'>();

    useEffect(() => {
        setThemeButtonText(theme === 'dark' ? 'Mode clair' : 'Mode sombre');
    }, [theme]);

    return (
        <footer className="bg-white dark:bg-gray-800 py-10 px-3">
            <ContainerLarge>
                <div className="grid grid-cols-12 gap-8 dark:text-white">
                    <Brand className="col-span-2 !justify-start hidden lg:block" />
                    <ul className="col-span-10 md:col-span-12 md:flex flex-row items-center md:space-x-8 font-bold">
                        <li>
                            <Link
                                href={`${links.about}&utm_content=footer-about-link`}
                                type="alternative">
                                À Propos
                            </Link>
                        </li>
                        <li>
                            <Link
                                href={`${links.jobs}&utm_content=footer-jobs-link`}
                                type="alternative">
                                Jobs
                            </Link>
                        </li>
                        <li>
                            <Link
                                href={`${links.contact}&utm_content=footer-contact-link`}
                                type="alternative">
                                Contact
                            </Link>
                        </li>
                        <li className="mt-5 md:mt-0 !ml-auto flex flex-row items-center">
                            <Button
                                className="flex items-center"
                                color="alternative"
                                onClick={switchTheme}>
                                <IconMoon className="mr-2" />
                                <span>{themeButtonText}</span>
                            </Button>
                        </li>
                    </ul>
                </div>
                <HorizontalRule className="my-10" />
                <div className="md:grid grid-cols-12 gap-8 dark:text-white">
                    <div className="md:col-span-6 lg:col-span-2">
                        <Heading4 className="lg:mb-12 mt-[0em]">Produit</Heading4>
                        <ul className="flex flex-col space-y-4 text-sm">
                            <li>
                                <Link
                                    href={`${links.faq}&utm_content=footer-faq-link`}
                                    type="alternative">
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href={`${links.features}&utm_content=footer-features-link`}
                                    type="alternative">
                                    Fonctionnalités
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href={`${links.prices}&utm_content=footer-prices-link`}
                                    type="alternative">
                                    Tarifs
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="md:col-span-6 lg:col-span-4">
                        <Heading4 className="mt-10 lg:mt-0 lg:mb-12">Comparaisons</Heading4>
                        <ul className="flex flex-col space-y-4 text-sm">
                            <li>
                                <Link
                                    href={`${links.partagerVSsubstack}&utm_content=footer-partager-vs-substack-link`}
                                    type="alternative">
                                    Partager.io vs Substack
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href={`${links.partagerVSkessel}&utm_content=footer-partager-vs-kessel-link`}
                                    type="alternative">
                                    Partager.io vs Kessel
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href={`${links.partagerVSmailchimpVSsendinblue}&utm_content=footer-partager-vs-mailchimp-vs-sendinblue-link`}
                                    type="alternative">
                                    Partager.io vs Mailchimp vs Sendinblue
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="place-self-stretch col-span-12 lg:col-span-6">
                        <Heading4 className="mt-10 lg:mt-0 lg:mb-12">Ressources</Heading4>
                        <ul className="md:columns-2 gap-8 text-sm">
                            <li className="mb-4">
                                <Link
                                    href={`${routes.getPost.getUrl(
                                        'grandir-votre-newsletter',
                                        463,
                                    )}?utm_source=${UTM_SOURCE}&utm_medium=web&utm_content=footer-create-good-publication-link`}
                                    type="alternative">
                                    Comment créer une bonne publication
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link
                                    href={`${routes.getPost.getUrl(
                                        'grandir-votre-newsletter',
                                        24,
                                    )}?utm_source=${UTM_SOURCE}&utm_medium=web&utm_content=footer-create-logo-link`}
                                    type="alternative">
                                    Comment créer un logo en 5 minutes pour votre publication
                                    partager.io
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link
                                    href={`${routes.getPost.getUrl(
                                        'grandir-votre-newsletter',
                                        486,
                                    )}?utm_source=${UTM_SOURCE}&utm_medium=web&utm_content=footer-create-articles-link`}
                                    type="alternative">
                                    Comment créer et personnaliser ses articles
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link
                                    href={`${routes.getPost.getUrl(
                                        'grandir-votre-newsletter',
                                        262,
                                    )}?utm_source=${UTM_SOURCE}&utm_medium=web&utm_content=footer-notion-link`}
                                    type="alternative">
                                    Votre bloc-notes toujours près de vous
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link
                                    href={`${routes.getPost.getUrl(
                                        'grandir-votre-newsletter',
                                        31,
                                    )}?utm_source=${UTM_SOURCE}&utm_medium=web&utm_content=footer-add-subscribers-link`}
                                    type="alternative">
                                    Comment ajouter des lecteurs à votre publication
                                </Link>
                            </li>
                            <li className="mb-4">
                                <Link
                                    href={`${routes.getPost.getUrl(
                                        'grandir-votre-newsletter',
                                        508,
                                    )}?utm_source=${UTM_SOURCE}&utm_medium=web&utm_content=footer-publication-strategy-link`}
                                    type="alternative">
                                    Etablissez un plan pour votre publication
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href={`${routes.getPost.getUrl(
                                        'grandir-votre-newsletter',
                                        432,
                                    )}?utm_source=${UTM_SOURCE}&utm_medium=web&utm_content=footer-growth-hack-audiance-link`}
                                    type="alternative">
                                    Comment faire grandir l&apos;audience de votre publication
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="md:flex lg:grid lg:grid-cols-12 lg:gap-8 text-sm mt-12 py-5">
                    <div className="lg:col-span-6 !text-gray-500">Partager © 2023</div>
                    <div className="md:ml-auto mt-5 md:mt-0 lg:col-span-6 lg:ml-0">
                        <ul className="md:flex flex-row md:space-x-4">
                            <li className="mb-2">
                                <Link
                                    href={`${links.mentionsLegal}&utm_content=footer-legal-link`}
                                    type="alternative"
                                    className="!text-gray-500">
                                    Mentions légales
                                </Link>
                            </li>
                            <li className="mb-2">
                                <Link
                                    href={`${links.privacy}&utm_content=footer-policies-link`}
                                    type="alternative"
                                    className="!text-gray-500">
                                    Confidentialité
                                </Link>
                            </li>
                            <li className="mb-2">
                                <Link
                                    href={`${links.cgs}&utm_content=footer-cgs-link`}
                                    type="alternative"
                                    className="!text-gray-500">
                                    CGS
                                </Link>
                            </li>
                            <li className="mb-2">
                                <Link
                                    href={`${links.cgu}&utm_content=footer-cgu-link`}
                                    type="alternative"
                                    className="!text-gray-500">
                                    CGU
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href={`${links.cookiesPolicy}&utm_content=footer-cookie-policies-link`}
                                    type="alternative"
                                    className="!text-gray-500">
                                    Politique des cookies
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </ContainerLarge>
        </footer>
    );
}
