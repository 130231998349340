import type { ReactNode } from 'react';

export interface ContainerLargeProps {
    className?: string;
    children: ReactNode;
}

export default function ContainerLarge({ className, children }: ContainerLargeProps): JSX.Element {
    return <div className={`container mx-auto grow ${className}`}>{children}</div>;
}
