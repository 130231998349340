import classNames from '../../../lib/classnames';

export interface HorizontalRuleProps {
    className?: string;
}

export default function HorizontalRule({ className }: HorizontalRuleProps): JSX.Element {
    return (
        <hr
            className={classNames('border-1 border-gray-300 my-6 dark:border-gray-600', className)}
        />
    );
}
