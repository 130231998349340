import classNames from '../../../lib/classnames';
import Link from '../../elements/Link';

export interface BrandIconProps {
    url: string;
    className?: string;
}

export default function BrandIcon({ className, url }: BrandIconProps): JSX.Element {
    return (
        <Link href={url} className={classNames('my-7 flex justify-center', className)}>
            <svg width="19" height="25" fill="none" className="h-6 fill-gray-900 dark:fill-white">
                <path d="M0 24.973V0h10.608c1.19 0 2.283.241 3.275.72a8.217 8.217 0 0 1 2.572 1.936 8.976 8.976 0 0 1 1.68 2.727c.396 1.01.597 2.04.597 3.095a8.79 8.79 0 0 1-.998 4.081 8.442 8.442 0 0 1-2.767 3.149c-1.18.808-2.575 1.214-4.184 1.214h-5.04v8.054H0v-.003Zm5.744-13.084h4.693c.443 0 .852-.127 1.224-.387.372-.257.678-.644.91-1.16.232-.518.351-1.148.351-1.901 0-.753-.134-1.413-.403-1.916-.27-.504-.607-.872-1.016-1.108a2.544 2.544 0 0 0-1.277-.352H5.744v6.824Z" />
            </svg>
        </Link>
    );
}
